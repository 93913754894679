// RUN BUILD WITH: ng build --configuration=dev

export const environment = {
  production: false,
  url: 'https://devcms.job.rocks/',
  apiUrl: 'https://devcms.job.rocks/api',
  ibanApi: "https://services.job.rocks/",
  chatUrl: "http://localhost:3000/",
  chatApiUrl: "http://localhost:3000/api",

  logoUrl: "assets/images/job.rocks-grey-logo.svg",
  // logoUrl: 'assets/images/Logo_Hotelis_web_650x145px.png',
  favIconUrl: "assets/images/icons/job.rocks.favicon.svg",
  // favIconUrl: 'assets/images/Logo_Hotelis_60x60px_3.png',
  agencyName: "jobrocks",
  // agencyName: 'Hotelis',
};